import React, { useEffect } from 'react';
import { throttle } from 'lodash';

import './home.scss';

import { Banner } from '../components/banner';
import { Community } from '../components/community';
import { Drops } from '../components/drops';
import { Featured } from '../components/featured';
import { InvestorAdvisors } from '../components/investorAdvisors';
import { LeaderShip } from '../components/leadership';
import { OurNews } from '../components/ourNews';
import { WhatWeDo } from '../components/what-we-do';
import { ComingSoon } from '../components/coming-soon';
import { Footer } from '../components/footer';

import { useLottie, useLottieInteractivity } from 'lottie-react';

import Home1 from '../images/HomeNewWorld.png';
import Home2 from '../images/HomeSelfExpression.png';

import Home3 from '../images/HomeLeftUnderscore.png';
import Home4 from '../images/HomeRightUnderscore.png';

import backgroundAnimation from '../animations/background.json';

// @ts-ignore
const Lottie = ({ lottieRef }) => {
  const lottieObj = useLottie({
    animationData: backgroundAnimation,
    rendererSettings: {
      className: 'home-lottie-animation',
      preserveAspectRatio: `xMidYMid ${
        window.innerWidth < 768 ? 'slice' : 'slice'
      }`,
    },
    style: {
      left: '50%',
      position: 'fixed',
      width: window.innerWidth < 768 ? `${518 * 0.7}px` : `${518 * 0.8}px`,
      height: window.innerWidth < 768 ? `${764 * 0.7}px` : `${764 * 0.8}px`,
      transform: `translate(-45%, ${
        window.innerWidth < 768 ? '55px' : '95px'
      })`,
      zIndex: -1,
    },
  });

  const Animation = useLottieInteractivity({
    lottieObj: {
      ...lottieObj,
      // @ts-ignore
      animationContainerRef: lottieRef,
    },
    mode: 'scroll',

    actions: [
      {
        visibility: [0, 1],
        type: 'seek',
        frames: [0, 84],
      },
    ],
  });

  return Animation;
};

type ImageCoords = {
  keyframe: number;
  xValue: number;
  yValue: number;
};

const image1Mid = {
  keyframe: 0.8,
  xValue: -85,
  yValue: 60,
};

const image1End = {
  keyframe: 5,
  xValue: -500,
  yValue: 60,
};

const image2Mid = {
  keyframe: 0.8,
  xValue: 78,
  yValue: 60,
};

const image2End = {
  keyframe: 5,
  xValue: 500,
  yValue: 0,
};

const image3Start = {
  xValue: -234,
  yValue: -20,
};

const image3End = {
  xValue: -266,
  yValue: 14,
  keyframe: 0.6,
};

const image4Start =
  window.innerWidth < 768
    ? { xValue: 0, yValue: 0 }
    : {
        xValue: 202,
        yValue: -20,
      };

const image4End =
  window.innerWidth < 768
    ? { xValue: 500, yValue: 0, keyframe: 0.6 }
    : {
        xValue: 292,
        yValue: 14,
        keyframe: 0.6,
      };

const getScrollPercent = (): number => {
  const result =
    (window.pageYOffset /
      (document.documentElement.scrollHeight -
        document.documentElement.clientHeight)) *
    100;

  return result;
};

export const Home: React.FC = () => {
  const lottieRef = React.useRef(null);

  const getImageCoords = (
    scrollPercent: number,
    imageEnd: ImageCoords,
    imageMid: ImageCoords | null,
    imageStart = { xValue: 0, yValue: 0 }
  ) => {
    const result = {
      opacity: Math.max(0, 1 - scrollPercent / imageEnd.keyframe),
      xValue:
        imageStart.xValue +
        (imageEnd.xValue - imageStart.xValue) *
          Math.min(scrollPercent / imageEnd.keyframe, 1),
      yValue:
        imageStart.yValue +
        -1 *
          ((imageMid || imageEnd).yValue - imageStart.yValue) *
          Math.min(scrollPercent / (imageMid || imageEnd).keyframe, 1),
    };

    return result;
  };

  const setTransform = (
    imageId: string,
    imageEnd: ImageCoords,
    imageMid: ImageCoords | null,
    imageStart?: { xValue: number; yValue: number }
  ) => {
    const scrollPercent = getScrollPercent();

    const imageEl = document.getElementById(imageId);

    const imageCoords = getImageCoords(
      scrollPercent,
      imageEnd,
      imageMid,
      imageStart
    );

    if (imageEl) {
      imageEl.style.transform = `translate3d(${imageCoords.xValue}px, ${imageCoords.yValue}px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`;
      imageEl.style.opacity = `${imageCoords.opacity}`;
    }
  };

  const setOverlayOpacity = () => {
    const scrollPercent = getScrollPercent();

    const dimmingStartPercent = window.innerWidth < 768 ? 10 : 22;
    const dimmingEndPercent = window.innerWidth < 768 ? 23 : 35;

    const dimmedOpacity = 0.9;

    let opacity = 0;

    if (scrollPercent > 35) {
      opacity = dimmedOpacity;
    } else if (scrollPercent < dimmingStartPercent) {
      opacity = 0;
    } else {
      opacity =
        ((scrollPercent - dimmingStartPercent) /
          (dimmingEndPercent - dimmingStartPercent)) *
        dimmedOpacity;
    }

    const overlayEl = document.getElementById('overlayHome');

    if (!overlayEl) {
      return;
    }

    overlayEl.style.background = `rgba(0, 0, 0, ${opacity})`;
  };

  useEffect(() => {
    const onScroll = () => {
      setOverlayOpacity();
      setTransform('imageHome1', image1End, image1Mid);
      setTransform('imageHome2', image2End, image2Mid);
      setTransform('imageHome3', image3End, null, image3Start);
      setTransform('imageHome4', image4End, null, image4Start);
    };

    const scrollHandler = throttle(onScroll, 100);

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  useEffect(() => {
    /*  const script = document.createElement('script');

    script.src =
      'https://www.google.com/recaptcha/api.js?render=explicit&onload=onRecaptchaLoadCallback';
    //  script.async = true;

    document.body.appendChild(script); */
  }, []);

  return (
    <div className="Home" id="MyHome">
      <div className="Home-bg">
        <div id="Home" className="overlay"></div>
        <div className="poster"></div>
      </div>
      <Lottie lottieRef={lottieRef} />
      <div className="overlay" id="overlayHome" />
      <div className="title-block">
        <img id="imageHome1" src={Home1} width={446} />
        <img id="imageHome2" className="image-2" src={Home2} width={511} />
        <div>
          <img
            id="imageHome3"
            src={Home3}
            width={68}
            style={{
              transform: `translate3d(${image3Start.xValue}px, ${image3Start.yValue}px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
            }}
          />
          <img
            id="imageHome4"
            src={Home4}
            width={484}
            style={{
              transform:
                window.innerWidth < 768
                  ? ''
                  : `translate3d(${image4Start.xValue}px, ${image4Start.yValue}px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
            }}
          />
        </div>
      </div>
      <Banner />
      <div className="spacer">
        <div className="followItem" ref={lottieRef}></div>
      </div>
      <WhatWeDo />
      <div>
        <ComingSoon />
      </div>
      <Drops />
      <Featured />
      <InvestorAdvisors />
      <LeaderShip />
      <OurNews />
      <Community />
      <Footer />
    </div>
  );
};
