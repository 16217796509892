import { useEffect, useRef, useState } from 'react';
/**
 * returns [width]
 */
export const useWindowSize = () => {
  const [screenSize, setscreenSize] = useState<number>(window.innerWidth);
  useEffect(() => {
    window.addEventListener('resize', () => {
      setscreenSize(window.innerWidth);
    });
    return () => {
      window.removeEventListener('resize', () => {
        setscreenSize(window.innerWidth);
      });
    };
  }, []);

  return screenSize;
};
