export const LogoText = () => (
  <svg
    width="244"
    height="19"
    viewBox="0 0 244 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M221.056 12.8697C221.519 12.6638 221.954 12.4026 222.353 12.0917C222.777 11.7593 223.149 11.365 223.457 10.9219C223.792 10.4381 224.05 9.90474 224.221 9.34113C224.414 8.69518 224.507 8.02343 224.497 7.34927V6.84442C224.494 6.41341 224.448 5.98374 224.361 5.56157C224.265 5.09357 224.114 4.63879 223.909 4.207C223.704 3.75122 223.446 3.32148 223.139 2.9269C222.813 2.4993 222.42 2.12675 221.975 1.82338C221.501 1.49018 220.976 1.23412 220.422 1.06471C219.78 0.8624 219.111 0.763683 218.438 0.772275H211.522C211.005 0.780746 210.493 0.866297 210.002 1.02609C209.437 1.19511 208.91 1.4704 208.449 1.83718C207.95 2.24313 207.541 2.74864 207.249 3.32142C207.032 3.76536 206.871 4.23418 206.769 4.71738V18.2769H211.025V13.4518H213.975L219.525 18.2548H226.05L220.19 13.1924C220.487 13.1074 220.776 12.9995 221.056 12.8697ZM219.765 8.6404C219.597 8.79964 219.399 8.92512 219.183 9.01008C218.925 9.10901 218.653 9.16943 218.378 9.1894H210.987V5.52294C210.977 5.45009 210.981 5.37596 211 5.30489C211.019 5.23383 211.052 5.16726 211.097 5.10912C211.157 5.06327 211.225 5.02967 211.298 5.01026C211.371 4.99086 211.447 4.98601 211.522 4.99601H218.419C218.677 4.98941 218.933 5.03638 219.172 5.13395C219.365 5.21155 219.54 5.33015 219.683 5.48156C219.839 5.64605 219.961 5.84024 220.041 6.05264C220.141 6.31897 220.205 6.59724 220.232 6.88028V7.35204C220.237 7.61385 220.193 7.87432 220.099 8.11898C220.026 8.31607 219.912 8.49559 219.765 8.64592V8.6404Z"
      fill="white"
    />
    <path
      d="M120.172 12.8697C120.634 12.6632 121.069 12.402 121.469 12.0917C121.893 11.7593 122.265 11.365 122.572 10.9219C122.908 10.4376 123.167 9.90442 123.339 9.34114C123.533 8.69519 123.626 8.02343 123.615 7.34928V6.84442C123.613 6.41325 123.566 5.98344 123.477 5.56158C123.382 5.09367 123.231 4.63889 123.028 4.207C122.822 3.75115 122.563 3.32142 122.255 2.92691C121.929 2.49867 121.536 2.12601 121.091 1.82339C120.615 1.49072 120.09 1.23472 119.535 1.06471C118.894 0.862372 118.226 0.763651 117.554 0.772279H110.616C110.099 0.780503 109.587 0.866059 109.096 1.02609C108.53 1.19567 108.002 1.47089 107.54 1.83718C107.041 2.24314 106.632 2.74864 106.34 3.32142C106.125 3.76576 105.964 4.2345 105.862 4.71738V18.2769H110.119V13.4518H113.068L118.619 18.2549H125.179L119.32 13.1924C119.611 13.1058 119.896 12.998 120.172 12.8697ZM110.103 9.18941V5.52295C110.092 5.4501 110.097 5.37597 110.116 5.3049C110.135 5.23383 110.168 5.16727 110.213 5.10913C110.254 5.06775 110.356 4.99602 110.638 4.99602H117.535C117.792 4.98931 118.047 5.03629 118.285 5.13396C118.479 5.2116 118.654 5.33016 118.798 5.48157C118.954 5.64621 119.075 5.8404 119.154 6.05264C119.255 6.31884 119.32 6.59712 119.347 6.88029V7.35204C119.352 7.61375 119.307 7.87397 119.215 8.11899C119.135 8.31843 119.013 8.49886 118.859 8.64868C118.691 8.80789 118.495 8.93339 118.28 9.01836C118.021 9.11729 117.75 9.17771 117.474 9.19768H110.103V9.18941Z"
      fill="white"
    />
    <path
      d="M181.239 0.730919H180.58V11.1537L170.99 1.15026C170.694 0.84086 170.308 0.633205 169.887 0.557113H169.87C169.459 0.473556 169.033 0.513889 168.645 0.672986C168.274 0.843401 167.955 1.1113 167.724 1.44821C167.482 1.79628 167.355 2.21086 167.359 2.6345V18.2356H171.592V7.83485L181.181 17.8355C181.385 18.0524 181.635 18.2203 181.912 18.3266C182.16 18.4184 182.423 18.4661 182.687 18.4673C182.972 18.4675 183.253 18.4113 183.515 18.3018C183.768 18.1989 183.997 18.047 184.191 17.8548C184.382 17.6632 184.535 17.4365 184.641 17.1872C184.758 16.9223 184.818 16.6356 184.817 16.3458V0.730919H181.231H181.239Z"
      fill="white"
    />
    <path
      d="M160.479 0.7309H159.819V11.1537L150.23 1.15024C149.934 0.840841 149.547 0.633186 149.126 0.557094H149.11C148.7 0.473426 148.274 0.513769 147.887 0.672968H147.874C147.502 0.843382 147.184 1.11128 146.952 1.44819C146.712 1.79689 146.585 2.21105 146.588 2.63448V18.2355H150.845V7.83483L160.421 17.8355C160.624 18.0524 160.874 18.2203 161.152 18.3266C161.401 18.4187 161.664 18.4663 161.93 18.4673C162.214 18.4678 162.495 18.4115 162.757 18.3018C163.01 18.1989 163.24 18.047 163.433 17.8548C163.625 17.6632 163.777 17.4365 163.883 17.1872C164 16.9223 164.061 16.6356 164.059 16.3458V0.7309H160.473H160.479Z"
      fill="white"
    />
    <path
      d="M243.896 12.8283C243.904 12.2346 243.822 11.6431 243.654 11.0737C243.503 10.5764 243.276 10.1055 242.98 9.67771C242.715 9.28511 242.391 8.93531 242.02 8.64041C241.675 8.35794 241.296 8.11889 240.892 7.92863C240.517 7.74733 240.121 7.61031 239.714 7.52032C239.345 7.43307 238.968 7.38316 238.588 7.37135H231.796C231.647 7.38403 231.496 7.36611 231.354 7.31867C231.212 7.27124 231.081 7.19529 230.969 7.09547C230.766 6.84575 230.667 6.52768 230.693 6.20713C230.668 5.88492 230.766 5.56522 230.969 5.31327C231.077 5.20723 231.207 5.12574 231.35 5.07434C231.493 5.02293 231.645 5.00281 231.796 5.01533H242.451V0.730896H231.796C231.209 0.739393 230.627 0.837063 230.069 1.02057C229.43 1.21688 228.835 1.53545 228.317 1.95857C227.756 2.42263 227.298 2.99858 226.971 3.64971C226.594 4.4364 226.41 5.30194 226.436 6.17403C226.412 7.04926 226.594 7.91789 226.968 8.70938C227.295 9.35951 227.754 9.93451 228.315 10.3978C228.835 10.8138 229.427 11.1308 230.061 11.333C230.62 11.5083 231.202 11.6013 231.788 11.6089H238.542C238.706 11.6055 238.869 11.6346 239.022 11.6944C239.143 11.7409 239.253 11.8145 239.342 11.9096C239.439 12.0128 239.514 12.1349 239.562 12.2682C239.62 12.435 239.648 12.6105 239.645 12.7869C239.668 13.1065 239.563 13.4221 239.353 13.6642C239.243 13.7671 239.112 13.845 238.969 13.8926C238.826 13.9402 238.675 13.9564 238.525 13.9401H226.538V18.1969H238.525C238.908 18.1943 239.29 18.1518 239.664 18.07C240.077 17.9832 240.479 17.8499 240.862 17.6727C241.264 17.4881 241.645 17.2586 241.996 16.9886C242.379 16.6905 242.713 16.3339 242.986 15.9319C243.272 15.507 243.495 15.0422 243.645 14.5525C243.816 13.9939 243.901 13.4124 243.896 12.8283Z"
      fill="white"
    />
    <path
      d="M190.166 0.730898C189.593 0.727779 189.042 0.95201 188.635 1.35439C188.45 1.5505 188.302 1.77859 188.199 2.02754C188.09 2.2899 188.035 2.57124 188.036 2.85518V13.4462C188.015 14.2188 188.18 14.9851 188.516 15.6809C188.81 16.2477 189.216 16.7496 189.708 17.1568C190.172 17.5246 190.702 17.8008 191.269 17.9707C191.762 18.1243 192.274 18.2061 192.789 18.2135H204.043V13.998H192.817C192.742 14.008 192.666 14.0032 192.593 13.9838C192.52 13.9643 192.452 13.9308 192.392 13.8849C192.347 13.8268 192.314 13.7602 192.295 13.6891C192.276 13.6181 192.272 13.5439 192.282 13.4711V11.6365H202.727V7.35204H192.282V4.9905H204.048V0.730898H190.166Z"
      fill="white"
    />
    <path
      d="M64.6594 0.730905C64.1323 0.724507 63.6074 0.79989 63.1035 0.954368C62.6621 1.09242 62.2443 1.2971 61.8647 1.56131C61.5168 1.8024 61.2072 2.09441 60.9461 2.42757C60.6996 2.73957 60.4938 3.08165 60.3336 3.44557C60.1828 3.7834 60.0663 4.13554 59.986 4.49668V4.51048C59.9182 4.83816 59.8831 5.17177 59.8812 5.5064V13.4545C59.8754 13.9852 59.9508 14.5136 60.1046 15.0215C60.242 15.4581 60.4418 15.8725 60.6978 16.252C60.944 16.595 61.2375 16.9015 61.5696 17.1624C61.8795 17.3972 62.2144 17.597 62.5683 17.7583C62.9058 17.9074 63.2568 18.0238 63.6166 18.1059H63.6359C63.9667 18.1711 64.3029 18.2053 64.6401 18.208H75.8933V13.998H64.6594C64.5121 14.0144 64.3641 13.9739 64.2456 13.8849C64.1593 13.7652 64.12 13.6179 64.1353 13.4711V5.54503C64.1353 5.2526 64.207 5.14776 64.2456 5.10913C64.3035 5.0636 64.37 5.03014 64.4411 5.01071C64.5121 4.99129 64.5864 4.98629 64.6594 4.99602H75.9126V0.730905H64.6594Z"
      fill="white"
    />
    <path
      d="M80.6033 0.730896C80.3192 0.730823 80.0379 0.787086 79.7757 0.896426C79.5186 1.00204 79.2851 1.15772 79.0887 1.35439C78.9045 1.5505 78.7577 1.77863 78.6556 2.02754C78.5461 2.28972 78.4898 2.57106 78.4901 2.85518V13.4462C78.4691 14.2188 78.6337 14.9851 78.9701 15.6809C79.2654 16.2471 79.6706 16.7489 80.1619 17.1568C80.6275 17.5241 81.1582 17.8003 81.7261 17.9707C82.2185 18.1245 82.7305 18.2063 83.2462 18.2135H94.4994V13.998H83.2573C82.9814 13.998 82.871 13.9263 82.8324 13.8849C82.7874 13.8268 82.7544 13.7602 82.7355 13.6891C82.7165 13.6181 82.7119 13.5439 82.7221 13.4711V11.6365H93.1669V7.35204H82.7221V4.9905H94.4884V0.730896H80.6033Z"
      fill="white"
    />
    <path
      d="M37.141 5.56158C37.0447 5.09401 36.8937 4.63938 36.6913 4.207C36.4866 3.75123 36.2282 3.32149 35.9216 2.92691C35.5956 2.49867 35.2025 2.12601 34.7574 1.82339C34.2816 1.49072 33.7566 1.23472 33.2014 1.06472C32.5609 0.862606 31.8922 0.763891 31.2206 0.772283H24.5471C24.0305 0.780506 23.5181 0.866063 23.027 1.0261C22.4629 1.19648 21.9359 1.47165 21.4737 1.83719C20.9762 2.24444 20.5677 2.74965 20.2737 3.32143C19.9382 4.0136 19.7736 4.77611 19.7936 5.54503V18.2769H24.0532V14.2794H31.2068C31.6371 14.2767 32.066 14.2305 32.4869 14.1415C32.9538 14.0477 33.4083 13.9005 33.8415 13.7028C34.3028 13.497 34.7372 13.2357 35.1354 12.9248C35.5596 12.5923 35.9316 12.198 36.2389 11.7551C36.5744 11.2708 36.8331 10.7376 37.0058 10.1743C37.1977 9.52806 37.2907 8.85651 37.2817 8.18245V6.84994C37.2791 6.41877 37.2329 5.98896 37.1438 5.56709L37.141 5.56158ZM33.0221 8.17969C33.0267 8.4414 32.9818 8.70161 32.8897 8.94664C32.8093 9.14608 32.6881 9.32651 32.5338 9.47633C32.3662 9.63554 32.1695 9.76104 31.9545 9.84601C31.6962 9.94493 31.4248 10.0054 31.1489 10.0253H24.0339V5.52295C24.0238 5.4501 24.0284 5.37597 24.0473 5.3049C24.0663 5.23384 24.0992 5.16727 24.1443 5.10913C24.1829 5.06775 24.2877 4.99602 24.5691 4.99602H31.1903C31.4472 4.98964 31.7028 5.0366 31.9407 5.13396H31.9545C32.1481 5.21228 32.323 5.33075 32.4676 5.48157C32.6232 5.64622 32.7442 5.84041 32.8235 6.05265C32.9239 6.31885 32.9889 6.59712 33.0166 6.88029L33.0221 8.17969Z"
      fill="white"
    />
    <path
      d="M56.2178 1.36267C56.0215 1.166 55.788 1.01033 55.5309 0.904711C55.2687 0.79523 54.9874 0.738961 54.7032 0.739181H48.0821C46.8914 0.727088 45.7097 0.947306 44.6032 1.3875C43.565 1.80565 42.6206 2.42652 41.8251 3.21383C41.0323 4.00022 40.4064 4.93857 39.985 5.97264C39.5378 7.08992 39.3138 8.28397 39.3256 9.48736V18.2438H43.5825V14.2794H52.5845V18.2549H56.8413V2.88002C56.8415 2.59589 56.7853 2.31456 56.6758 2.05238C56.5705 1.79432 56.4148 1.55985 56.2178 1.36267ZM52.5845 10.0226H43.5825V9.4984C43.5654 8.87042 43.6645 8.24468 43.8749 7.65276C44.0491 7.19691 44.3042 6.77632 44.6281 6.41129C44.9076 6.10035 45.2401 5.84159 45.6102 5.6471C45.9505 5.46631 46.3082 5.32027 46.6779 5.21121C46.9663 5.12724 47.2618 5.06998 47.5607 5.04016H47.5745C47.8752 5.00706 48.0324 4.99602 48.0849 4.99602H52.5845V10.0226Z"
      fill="white"
    />
    <path
      d="M139.721 0.730896H139.059V13.9897H134.56C134.502 13.9897 134.339 13.9897 134.049 13.9566C133.362 13.8744 132.696 13.6648 132.085 13.3387C131.716 13.1441 131.384 12.8853 131.106 12.5745C130.782 12.2137 130.526 11.7967 130.352 11.344C130.14 10.7497 130.041 10.1209 130.06 9.49011V0.730896H125.803V9.49011C125.794 10.4223 125.917 11.351 126.167 12.2489C126.392 13.0372 126.731 13.7882 127.174 14.478C127.596 15.1105 128.106 15.6791 128.689 16.1664C129.245 16.629 129.856 17.0219 130.507 17.3362C131.148 17.6329 131.819 17.8569 132.51 18.0038C133.178 18.1467 133.86 18.2198 134.543 18.2217H141.164C141.448 18.2222 141.729 18.1669 141.992 18.059C142.241 17.955 142.47 17.8074 142.668 17.6231C142.864 17.4265 143.02 17.193 143.126 16.9361C143.235 16.6691 143.29 16.3832 143.289 16.0947V0.730896H139.702H139.721Z"
      fill="white"
    />
    <path
      d="M17.3022 11.0737C17.1512 10.5764 16.9241 10.1055 16.629 9.67771C16.3636 9.2851 16.0399 8.93531 15.669 8.6404C15.323 8.35915 14.9441 8.12106 14.5406 7.93139C14.1646 7.74965 13.7682 7.6135 13.3598 7.52584H13.346C12.9768 7.43842 12.5997 7.38851 12.2205 7.37686H5.4117C5.26224 7.38954 5.11177 7.37162 4.96947 7.32419C4.82717 7.27675 4.69602 7.2008 4.58406 7.10098C4.39845 6.84092 4.29868 6.52939 4.29868 6.20989C4.29868 5.89038 4.39845 5.57886 4.58406 5.3188C4.6922 5.21214 4.82222 5.13026 4.96512 5.07881C5.10803 5.02737 5.2604 5.00759 5.4117 5.02084H16.0662V0.730896H5.4117C4.82468 0.739393 4.24236 0.837063 3.6847 1.02057C3.04504 1.2157 2.44986 1.53439 1.93285 1.95857C1.37206 2.42142 0.914395 2.99655 0.589307 3.64696C0.212071 4.43365 0.028562 5.29918 0.0541014 6.17127C0.0306094 7.04671 0.213975 7.91536 0.589307 8.70661C0.914504 9.35772 1.37323 9.93301 1.93561 10.395C2.45654 10.8111 3.04938 11.1281 3.6847 11.3302C4.24403 11.5051 4.82573 11.598 5.4117 11.6061H12.1653C12.3294 11.6028 12.4925 11.6318 12.6453 11.6916C12.7669 11.7382 12.8764 11.8117 12.9653 11.9068C13.0635 12.0093 13.1388 12.1316 13.186 12.2655C13.2436 12.4322 13.2716 12.6077 13.2688 12.7841C13.2923 13.1034 13.1881 13.4189 12.9791 13.6614C12.8689 13.7638 12.738 13.8414 12.5952 13.889C12.4524 13.9366 12.3011 13.9531 12.1515 13.9373H0.178247V18.1942H12.1653C12.5484 18.1915 12.9303 18.149 13.3047 18.0672C13.7185 17.9808 14.1211 17.8475 14.5047 17.67C14.9064 17.4853 15.2858 17.2558 15.6359 16.9858C16.02 16.6884 16.3543 16.3317 16.6263 15.9292C16.9127 15.5043 17.1348 15.0395 17.2856 14.5498C17.4615 13.978 17.5472 13.3823 17.5394 12.7841C17.5443 12.2055 17.4643 11.6292 17.3022 11.0737Z"
      fill="white"
    />
  </svg>
);
