// @ts-nocheck
import styles from './ourNews.module.scss';
import { OurNewsItem } from './ourNewsItem';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useWindowSize } from '../../utils/hooks/resizeListener';
import { useRef, useState } from 'react';

export interface OurNewsModel {
  id: number;
  title: string;
  desc: string;
  img: string;
  link: string;
}
const news: OurNewsModel[] = [
  {
    id: 10,
    title: 'Balmain releases first phygital Unicorn sneaker collection',
    desc: 'Balmain has partnered with digital fashion community Space Runners to translate Unicorn sneakers into phygital wearables that unlock special powers and bridge Web2 with Web3.',
    img: '/img/featured/vogue.svg',
    link: 'https://www.voguebusiness.com/technology/balmain-releases-first-phygital-unicorn-sneaker-collection',
  },
  {
    id: 9,
    title: 'How High Tech Is Transforming Fashion',
    desc: 'From AI-designed collections to the ultimate metaverse drip.',
    img: '/img/featured/elle.svg',
    link: 'https://www.voguebusiness.com/technology/balmain-releases-first-phygital-unicorn-sneaker-collection',
  },
  {
    id: 3,
    title: 'Pantera and Polychain Lead $10M Bet on Metaverse Fashionistas',
    desc: '“Fashion will be just as – if not more – important in the metaverse than in the real world,” Pantera’s Paul Veradittakit said.',
    img: '/img/featured/coindesk.svg',
    link: 'https://www.coindesk.com/business/2022/03/07/pantera-and-polychain-lead-10m-bet-on-metaverse-fashionistas/',
  },
  {
    id: 1,
    title:
      'Space Runners Announce "NBA Champions" Sneaker NFTs With Kyle Kuzma & Nick Young',
    desc: 'Space Runners — the first dedicated fashion brand for the Metaverse — has just announced a new NBA Champions partnership with Kyle Kuzma and Nick Young.',
    img: '/img/featured/hypebeast.svg',
    link: 'https://hypebeast.com/2021/12/nba-champions-kyle-kuzma-nick-young-space-runners-fashion-footwear-nfts',
  },
  {
    id: 2,
    title:
      'How Entrepreneurs Can Innovate And Stay Ahead Of The Curve In Business',
    desc: 'Space Runners co-founder Ozgur says that “we are constantly open to innovation and try to come up with new ideas every day. We try to be as agile as possible while also keeping our long term mission stable.”',
    img: '/img/featured/forbes.svg',
    link: 'https://www.forbes.com/sites/ashiraprossack1/2021/12/28/how-entrepreneurs-can-innovate-and-stay-ahead-of-the-curve-in-business/?sh=4576e7f320ac',
  },
  {
    id: 0,
    title:
      'Space Runners Raises $10M with Polychain, Pantera, Jump Crypto, and Accel to Build the First Fashion Metaverse On Blockchain',
    desc: 'Co-led by Polychain and Pantera Capital, along with Accel, Jump Crypto, and other participants including Yat Siu (Co-founder and Chairman of Animoca Brands), and Justin Kan (Co-founder of Twitch and Fractal).',
    img: '/img/featured/insider.svg',
    link: 'https://markets.businessinsider.com/news/stocks/space-runners-raises-10m-with-polychain-pantera-jump-crypto-and-accel-to-build-the-first-fashion-metaverse-on-blockchain-1031260211',
  },
  {
    id: 5,
    title: 'Polychain, Pantera Lead $10M Investment in Metaverse Fashion House',
    desc: 'Space Runners is the first metaverse-only fashion house, but various IRL brands are migrating to the virtual world too.',
    img: '/img/featured/decrypt.svg',
    link: 'https://decrypt.co/94600/polychain-pantera-lead-10m-investment-metaverse-fashion-house',
  },
  {
    id: 6,
    title:
      'Space Runners raises $10 million to build a blockchain-backed fashion metaverse',
    desc: 'Space Runners, a company focused on creating NFTs and digital wearables, has raised $10 million in a round co-led by Pantera Capital and Polychain Capital.',
    img: '/img/featured/blockcrypto.svg',
    link: 'https://www.theblockcrypto.com/linked/136586/space-runners-raises-10-million-to-build-a-blockchain-backed-fashion-metaverse',
  },
  {
    id: 7,
    title:
      'Space Runners Raises $10M to Create the First Fashion-focused Metaverse',
    desc: '‘Spaceverse‘, by Space Runners, is the first fashion-focused metaverse and end-to-end fashion ecosystem built using blockchain technology. The company’s mission is to bring the community, brands, and celebrities together.',
    img: '/img/featured/investing.svg',
    link: 'https://www.investing.com/news/cryptocurrency-news/space-runners-raises-10m-to-create-the-first-fashionfocused-metaverse-2781832',
  },
  {
    id: 8,
    title: 'Space Runners Raises $10m to Develop Fashion Metaverse',
    desc: 'Space Runners partners with brands and celebrities to design digitally wearable NFTs through Augmented Reality (AR).',
    img: '/img/featured/yahoo.svg',
    link: 'https://finance.yahoo.com/news/space-runners-raises-10m-develop-053526924.html',
  },
];
export const OurNews = () => {
  let width = useWindowSize();
  const pageSize: number = 3;
  let currentPage: number = 0;
  const [presentedItems, setPresentedItems] = useState<number[]>([0, 1, 2]);

  const loadMore = () => {
    const totalPages = news.length / pageSize;
    console.log(currentPage, totalPages);
    if (currentPage + 1 < totalPages) {
      currentPage++;
      setPresentedItems([
        ...presentedItems,
        pageSize * currentPage,
        pageSize * currentPage + 1,
        pageSize * currentPage + 2,
      ]);
    }
    console.log(currentPage, totalPages);
  };
  return (
    <>
      <div className={`${styles.ourNews}`} id="news">
        <div className={styles.title}>NEWS FROM US</div>
        {width > 760 && (
          <div className={styles.wrapper}>
            <PerfectScrollbar>
              <div className={styles.items}>
                {news.map((item: OurNewsModel) => (
                  <OurNewsItem key={`news-${item.id}`} item={item} />
                ))}
              </div>
            </PerfectScrollbar>
            <div className={styles.overLay}></div>
          </div>
        )}
        {width <= 760 && (
          <>
            <div className={styles.wrapper}>
              <div className={styles.items}>
                {presentedItems.map(
                  (index: number) =>
                    news[index] && (
                      <OurNewsItem key={news[index].id} item={news[index]} />
                    )
                )}
              </div>
            </div>
            {presentedItems.length < news.length && (
              <div className={styles.loadMore} onClick={loadMore}>
                LOAD MORE NEWS
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
