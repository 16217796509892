import { BurnData } from '.';
import styles from './OldCloset.module.scss';

type PageProps = {
  burn: BurnData;
};

export const BurnItem: React.FC<PageProps> = ({ burn }) => {
  return (
    <div className={styles.burnItemBox}>
      <div className={styles.burnItemImg}>
        <img
          src={`https://metadata-temp-api.spacerunners.com/images/${burn.id}`}
          width="100%"
          alt={'#' + burn.id}
        />
      </div>
      <div className={styles.burnItemInfo}>
        <h3>{`Space Runners x NBA Champions #${burn.id}`}</h3>
        <p>
          <span>BURN PRICE</span>
          {burn.burn_price.toFixed(2)} SOL
        </p>
      </div>
    </div>
  );
};
