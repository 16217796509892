// @ts-nocheck
import Carousel from 'react-multi-carousel';
import styles from './Featured.module.scss';

declare type LinkType = {
  title: string;
  url: string;
  img: string;
};

const links: LinkType[] = [
  //   {
  //     title: 'forbes',
  //     url: 'https://www.forbes.com/sites/ashiraprossack1/2021/12/28/how-entrepreneurs-can-innovate-and-stay-ahead-of-the-curve-in-business/',
  //     img: '/img/featured/forbes.png',
  //   },
  {
    title: 'businessInsider',
    url: 'https://markets.businessinsider.com/news/stocks/space-runners-raises-10m-with-polychain-pantera-jump-crypto-and-accel-to-build-the-first-fashion-metaverse-on-blockchain-1031260211',
    img: '/img/featured/insider.svg',
  },
  {
    title: 'coindesk',
    url: 'https://www.coindesk.com/business/2022/03/07/pantera-and-polychain-lead-10m-bet-on-metaverse-fashionistas/',
    img: '/img/featured/coindesk.svg',
  },
  {
    title: 'theblock',
    url: 'https://www.theblockcrypto.com/linked/136586/space-runners-raises-10-million-to-build-a-blockchain-backed-fashion-metaverse',
    img: '/img/featured/blockcrypto.svg',
  },
  {
    title: 'decrypt',
    url: 'https://decrypt.co/94600/polychain-pantera-lead-10m-investment-metaverse-fashion-house',
    img: '/img/featured/decrypt.svg',
  },
  {
    title: 'elle',
    url: 'https://www.elle.com/fashion/a40602797/high-tech-fashion-trends/',
    img: '/img/featured/elle.svg',
  },
  {
    title: 'bloomberg',
    url: 'https://www.bloomberg.com/press-releases/2021-12-06/nba-champions-kyle-kuzma-nick-young-to-launch-fashion-nfts-for-the-metaverse',
    img: '/img/featured/bloomberg.svg',
  },
  {
    title: 'cointelegraph',
    url: 'https://cointelegraph.com/press-releases/nba-star-kyle-kuzma-partners-with-space-runners-to-release-metashoes-for-metaverses',
    img: '/img/featured/cointelegraph.svg',
  },
  {
    title: 'yahoo',
    url: 'https://finance.yahoo.com/news/nba-champions-kyle-kuzma-nick-091100038.html',
    img: '/img/featured/yahoo.svg',
  },
  {
    title: 'hypebeast',
    url: 'https://hypebeast.com/2021/12/nba-champions-kyle-kuzma-nick-young-space-runners-fashion-footwear-nfts',
    img: '/img/featured/hypebeast.svg',
  },
  //   {
  //     title: 'niceKicks',
  //     url: 'https://www.nicekicks.com/kyle-kuzma-and-nick-young-collaborate-with-space-runners-on-a-sneaker-nft-collection/',
  //     img: '/img/featured/niceKicks.png',
  //   },
  {
    title: 'investing',
    url: 'https://www.investing.com/news/cryptocurrency-news/space-runners--the-future-of-fashion-footwear-is-here-2682073',
    img: '/img/featured/investing.svg',
  },
];

export const Featured = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 12,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 8,
      paritialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 5,
      paritialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      paritialVisibilityGutter: 30,
    },
  };
  return (
    <div className={`${styles.featuredBox}`}>
      <div className={styles.title}>FEATURED ON</div>
      <div className={styles.featuredLinks}>
        <Carousel
          swipeable={false}
          draggable={false}
          showDots={false}
          arrows={false}
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          transitionDuration={500}
          containerClass="carousel-container"
          dotListClass="custom-dot-list-style"
          itemClass={styles.carouselItem}
        >
          {links.map((link: LinkType, index: number) => (
            <div className={styles.linkItem} key={link.title}>
              <a href={link.url} target="_blank">
                <img src={link.img} alt={link.title} />
              </a>
            </div>
          ))}
        </Carousel>
        {/* <div className={styles.right}></div>
        <div className={styles.left}></div> */}
      </div>
    </div>
  );
};
