import { Link } from 'react-router-dom';
import { BurnData, BurnGroupData } from '.';
import styles from './OldCloset.module.scss';

type PageProps = {
  burnDate: string;
  burns: BurnGroupData;
};

export const BurnGroupItem: React.FC<PageProps> = ({ burnDate, burns }) => {
  return (
    <a href={`#${burnDate}`} className={styles.burnGroupBox}>
      <h3>{burnDate}</h3>
      <div className={styles.burnGroupInfo}>
        <p>
          <span>Casualties:</span>
          <span>{burns.nfts.length}</span>
        </p>
        <p>
          <span>Avg. Cost:</span>
          <span>{burns.avg_cost.toFixed(2)}</span>
        </p>
        <p>
          <span>
            <strong>Total Spent:</strong>
          </span>
          <span>{burns.total_spent.toFixed(2)} SOL</span>
        </p>
      </div>
    </a>
  );
};
