import styles from './WhatWeDo.module.scss';

const ITEMS = [
  {
    title: 'What to wear',
    color: '#c0dedb',
    name: 'Digital Collectibles',
    description:
      'An experimental project exploring the space between technology and culture.',
    image: 'img/what-we-do/SR_WaterJump_IronRider.png',
  },
  {
    title: 'Where to wear it',
    color: '#B1A2DD',
    name: 'Spaceverse',
    linkTo: 'https://spaceverse.spacerunners.com',
    description:
      'Fashion-focused metaverse which rewards meaningful connections, relentless creativity, and self-discovery.',
    image: 'img/what-we-do/ABLO_FP_v05.png',
  },
  {
    title: 'How to make it',
    color: '#859DE2',
    name: 'Ablo AI',
    linkTo: 'https://ablo.ai',
    description:
      'AI powered design tool that enables anyone to customize your products.',
    video:
      'https://uploads-ssl.webflow.com/64a578c6af7743f274e283e2/64a5794d3dba19099746487a_ABLO_FP_v05_vertical-transcode.mp4',
  },
];

export const WhatWeDo = () => {
  return (
    <>
      <div className={`${styles.drops}`} id="drops">
        <div className={styles.title}>What We Do</div>
        <div className={styles.subtitle}>
          Space Runners is a fashion-tech platform that empowers you to create,
          collaborate and launch your own collections - both in physical and
          digital worlds.
        </div>
        <div className={styles.items}>
          {ITEMS.map(
            (
              { color, linkTo, title, name, image, description, video },
              index
            ) => (
              <div
                className={styles.item}
                key={index}
                onClick={
                  linkTo
                    ? () => {
                        window.open(linkTo, '_blank', 'noopener,noreferrer');
                      }
                    : () => {}
                }
                style={linkTo ? { cursor: 'pointer' } : {}}
              >
                <p className={styles.itemTitle} style={{ color }}>
                  {title}
                </p>
                {image ? (
                  <img
                    src={image}
                    style={{ borderRadius: '20px' }}
                    width="373px"
                  />
                ) : null}
                {video ? (
                  <video
                    autoPlay
                    height={213}
                    width={379}
                    loop
                    muted
                    playsInline
                    className={styles.video}
                    src={video}
                    style={{
                      borderRadius: '20px',
                    }}
                  />
                ) : null}
                <p className={styles.itemName} style={{ color }}>
                  {name}
                </p>
                <p className={styles.itemDescription}>{description}</p>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};
