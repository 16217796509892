import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { DropsModel } from '..';
import styles from './dropsItem.module.scss';
export const DropItem = ({ item }: { item: DropsModel }) => {
  const initialDescBottom = '-200px';
  const finalDescBottom = '13%';
  const [descBottom, setDescBottom] = useState<string>(initialDescBottom);
  const [imgClass, setimgClass] = useState<string>('');
  const setHoverAnimations = () => {
    if (!isMobile) {
      setDescBottom(finalDescBottom);
      setimgClass(styles.enLargen);
    }
  };
  const removeHoverAnimations = () => {
    if (!isMobile) {
      setDescBottom(initialDescBottom);
      setimgClass('');
    }
  };
  return (
    <>
      <a
        href={item.link}
        target="_blank"
        className={styles.dropItemWrapper}
        onMouseOver={setHoverAnimations}
        onMouseLeave={removeHoverAnimations}
        onClick={(e) => {
          isMobile &&
            (descBottom == initialDescBottom
              ? setDescBottom(finalDescBottom)
              : setDescBottom(initialDescBottom));
          if (item.link == '') e.preventDefault();
        }}
      >
        <div className={styles.titleContent}>
          <div className={styles.title}>{item.title}</div>
          {item.chainImg ? (
            <img className={styles.img} src={item.chainImg} />
          ) : null}
        </div>
        <div className={styles.image}>
          <img src={item.img} width="100%" className={imgClass} />
          <div
            className={`${styles.desc} frostyGlass textBody`}
            style={{ bottom: descBottom }}
          >
            {item.desc}
          </div>
        </div>{' '}
        <div
          className={`textBody ${styles.footer}`}
          style={{ color: item.isSpecial ? '#FFF96D' : 'white' }}
        >
          {item.footer}
        </div>
      </a>
    </>
  );
};
