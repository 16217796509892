import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  DISCORD_URL,
  INSTAGRAM_URL,
  MEDIUM_URL,
  TWITTER_URL,
} from '../../constant';
import {
  IconDiscord,
  IconInstagram,
  IconLogo,
  IconMedium,
  IconTwitter,
} from '../../ui/Icon';
import { LogoText } from '../../ui/Icon/logoText';
import styles from './Footer.module.scss';

const logoColors: string[] = [
  '#E54040',
  '#FFF96D',
  '#FFFACA',
  '#BDBCF8',
  '#B0E7FF',
  '#9CFCC3',
];

export const Footer = () => {
  const [logoColorNum, setLogoColorNum] = useState<number>(1);

  return (
    <div className={styles.footerBox}>
      <Link
        to="/"
        className={styles.logo}
        onMouseEnter={() => {
          setLogoColorNum((logoColorNum + 1) % logoColors.length);
        }}
      >
        <IconLogo color={logoColors[logoColorNum]} />
        <LogoText />
      </Link>

      <div className={styles.termsLinks}>
        <div className={styles.socialLink}>
          <a className={styles.disabled} target="_blank">
            <IconDiscord />
          </a>
          <a href={TWITTER_URL} target="_blank">
            <IconTwitter />
          </a>
          <a href={MEDIUM_URL} target="_blank">
            <IconMedium />
          </a>
          <a href={INSTAGRAM_URL} target="_blank">
            <IconInstagram />
          </a>
        </div>
        <div className={styles.rightContent}>
          <p>
            <a href="mailto:contact@spacerunners.com" target="_blank">
              Contact Us
            </a>
          </p>
          <p>
            {/* ©2022 Spacerunners Inc. */}
            <Link to="/terms">Terms of Service</Link>
          </p>
        </div>
      </div>
    </div>
  );
};
