import { useEffect, useState } from 'react';
import { Header } from '../header';
import styles from './Banner.module.scss';

const imgs: string[] = [
  '/img/hero/0',
  '/img/hero/1',
  '/img/hero/2',
  '/img/hero/3',
  '/img/hero/4',
  '/img/hero/5',
];

export const Banner = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [num, setNum] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setNum((num) => (num + 1) % 6);
    }, 2000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div className={`${styles.bannerBox}`}>
      <div className={styles.header}>
        <Header />
      </div>
    </div>
  );
};
