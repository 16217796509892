import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { AdvisorsModel } from '..';
import { IconWhiteLinkedin, LinkIcon } from '../../../ui/Icon';
import styles from './advisorItem.module.scss';
export const AdvisorItem = ({ item }: { item: AdvisorsModel }) => {
  const initialDescTop = '150px';
  const finalDescTop = '27%';
  const [descTop, setDescTop] = useState<string>(initialDescTop);

  const setHoverAnimations = () => {
    if (!isMobile) {
      setDescTop(finalDescTop);
    }
  };
  const removeHoverAnimations = () => {
    if (!isMobile) {
      setDescTop(initialDescTop);
    }
  };
  return (
    <>
      <div
        className={styles.advisorsItem}
        onMouseOver={setHoverAnimations}
        onMouseLeave={removeHoverAnimations}
        onClick={() =>
          isMobile &&
          (descTop == initialDescTop
            ? setDescTop(finalDescTop)
            : setDescTop(initialDescTop))
        }
      >
        <div className={styles.img}>
          <img src={item.img} width="80px" alt="" />
          {/* <img src={item.img} alt="" /> */}
          <a
            href={item.link}
            className={`${styles.link} frostyGlass`}
            style={{ top: descTop }}
          >
            {item.linkedin ? <IconWhiteLinkedin /> : <LinkIcon />}
          </a>
        </div>
        <div className={styles.name}>{item.name}</div>
        <div className={`${styles.name} ${styles.lastName}`}>
          {item.lastName}
        </div>
        <div className={styles.company}>{item.company}</div>
        <div className={`${styles.role} ${styles.company}`}>{item.role}</div>
      </div>
    </>
  );
};
