import styles from './Roadmap.module.scss';
//import PerfectScrollbar from 'react-perfect-scrollbar';
import { useWindowSize } from '../../utils/hooks/resizeListener';
import { Header } from '../header';

declare type RoadData = {
  date: string;
  done: boolean;
  title: string;
  description: string[];
};

const roads: RoadData[] = [
  {
    date: '',
    done: true,
    title: '',
    description: [],
  },
  {
    date: '2021',
    done: true,
    title: '',
    description: [],
  },
  {
    date: 'LAUNCH 1ST NFT COLLECTION',
    done: true,
    title: '',
    description: [
      'Hit 100K community members before 1st drop',
      '1st NFT drop: NBA Champions sneaker collection with Kyle Kuzma & Nick Young',
    ],
  },
  {
    date: '2022',
    done: false,
    title: '',
    description: [],
  },
  {
    date: 'EXPAND NFT COLLECTION & UTILITIES',
    done: false,
    title: '',
    description: [
      'Earn Rewards',
      'AR & Social Media Filters',
      'NFT Integration With other Metaverses',
      'NFT Merge & Upgrade Feature',
      '2nd NFT Collection Drop',
    ],
  },
  {
    date: 'LAUNCH of FASHION METAVERSE',
    done: false,
    title: '',
    description: [
      '$SPACE Token Launch',
      'SPACEverse: Our Own Fashion Metaverse Teaser',
      'SPACEverse Beta Launch',
    ],
  },

  {
    date: 'OTHER DEVELOPMENTS',
    done: false,
    title: '',
    description: [
      'DesignSPACE Voting Platform Release',
      'Fashion NFT Marketplace',
      'Space Runners Official Merch Store',
    ],
  },
];

export const Roadmap = () => {
  const width = useWindowSize();
  return (
    <>
      <div className={styles.header}>
        <Header />
      </div>
      <div className={styles.roadmapBg}>
        {
          <div className={styles.roadmapBox}>
            <div className={styles.title}>ROADMAP</div>
            {/* <PerfectScrollbar> */}
            <div className={styles.roads}>
              {roads.map((road: RoadData, index: number) => (
                <div
                  className={styles.road}
                  key={`${road.date}-${index}`}
                  style={{
                    borderColor: road.done ? '#3E3E3E' : 'white',
                    marginTop: !road.date ? '22px' : '0px',
                  }}
                >
                  <h3 style={{ opacity: road.done ? 0.5 : 1 }}>{road.date}</h3>
                  <div
                    className={styles.line}
                    style={{
                      background:
                        road.date === 'OTHER DEVELOPMENTS'
                          ? 'linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 99.38%)'
                          : 'white',

                      opacity: road.done ? 0.2 : 1,
                    }}
                  >
                    {road.date && (
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="20"
                          cy="20"
                          r="18"
                          fill={road.done ? '#3E3E3E' : '#121212'}
                          stroke={road.done ? '#3E3E3E' : 'white'}
                          strokeWidth="3"
                        />
                        <circle
                          cx="20"
                          cy="20"
                          r="5"
                          fill={road.done ? '#3E3E3E' : 'white'}
                        />
                      </svg>
                    )}
                  </div>

                  {road.title !== '' && (
                    <h4 style={{ color: '#3E3E3E' }}>{road.title}</h4>
                  )}
                  {road.description.map((x: string, index: number) => (
                    <p
                      key={`${road.title}-${index}`}
                      className={road.done ? styles.done : ''}
                    >
                      {x}
                    </p>
                  ))}
                </div>
              ))}
            </div>
            {/* </PerfectScrollbar> */}
          </div>
        }
        {/* {width <= 760 && (
          <div className={styles.roadMobile}>
            <div className={styles.title}>ROADMAP</div>
            <div className={styles.roads}>
              {roads.map(
                (road: RoadData, index: number) =>
                  road.date && (
                    <div
                      className={styles.road}
                      key={`${road.date}-${index}`}
                      style={{ borderColor: road.done ? '#3E3E3E' : 'white' }}
                    >
                      <h3 style={{ color: road.done ? '#3E3E3E' : 'white' }}>
                        {road.date}
                      </h3>
                      <div
                        className={styles.line}
                        style={{ background: road.done ? '#3E3E3E' : 'white' }}
                      >
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="20"
                            cy="20"
                            r="18"
                            fill="#3E3E3E"
                            stroke={road.done ? '#3E3E3E' : 'white'}
                            strokeWidth="4"
                          />
                          <circle
                            cx="20"
                            cy="20"
                            r="7"
                            fill={road.done ? '#3E3E3E' : 'white'}
                          />
                        </svg>
                      </div>

                      {road.title !== '' && (
                        <h4 style={{ color: '#3E3E3E' }}>{road.title}</h4>
                      )}
                      {road.description.map((x: string, index: number) => (
                        <p
                          key={`${road.title}-${index}`}
                          className={road.done ? styles.done : ''}
                        >
                          {x}
                        </p>
                      ))}
                    </div>
                  )
              )}
            </div>
          </div>
        )} */}
      </div>
    </>
  );
};
