// @ts-nocheck
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.scss';
import { Home } from './pages/home';
import { Terms } from './pages/terms';
import { OldClosetPage } from './pages/oldCloset';
import { RoadmapPage } from './pages/roadmap';

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/old-closet" component={OldClosetPage} exact />
          <Route path="/roadmap" component={RoadmapPage} exact />
          <Route>
            <Switch>
              <Route path="/terms" component={Terms} exact />
              <Route path="/" component={Home} exact />
            </Switch>
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
