import { isMobile } from 'react-device-detect';
import styles from './leadership.module.scss';
import { LeadershipItem } from './leadershipItem/leadershipItem';
export interface LeaderShipModel {
  name: string;
  role: string;
  img: string;
  link: string;
}
const leaders: LeaderShipModel[] = [
  {
    name: 'Won',
    role: 'Co-CEO',
    img: 'img/leadership/Won.png',
    link: 'https://www.linkedin.com/in/won-soh',
  },
  {
    name: 'Deniz',
    role: 'Co-CEO',
    img: 'img/leadership/Deniz.png',
    link: 'https://www.linkedin.com/in/denizozzgur/',
  },
  {
    name: 'Karim',
    role: 'CTO',
    img: 'img/leadership/Karim.png',
    link: 'https://www.linkedin.com/in/karimvarela',
  },
  {
    name: 'Rohan',
    role: 'Head of Product',
    img: 'img/leadership/Rohan.png',
    link: 'https://www.linkedin.com/in/rohanchhabra/',
  },
  {
    name: 'Una',
    role: 'Head of Marketing',
    img: 'img/leadership/Una.png',
    link: 'https://www.linkedin.com/in/una-rokvic',
  },
  {
    name: 'Josh',
    role: 'Head of Operations',
    img: 'img/leadership/Josh.png',
    link: 'https://www.linkedin.com/in/joshua-ramjit-03653a56/',
  },
  {
    name: 'Michael',
    role: 'Head of Partnerships',
    img: 'img/leadership/Michael.png',
    link: 'https://www.linkedin.com/in/michaelscarpellini',
  },
  {
    name: 'Minjin',
    role: 'Creative Director',
    img: 'img/leadership/Minjin.png',
    link: 'https://www.linkedin.com/in/minjin-kang-384347b7/',
  },
  {
    name: 'Mijoo',
    role: 'Creative Director',
    img: 'img/leadership/Mijoo.png',
    link: 'https://www.linkedin.com/in/mijoo-kim-740186104/',
  },
];
export const LeaderShip = () => {
  return (
    <>
      <div className={`${styles.leaderShip} container`} id="team">
        <div className={styles.title}>LEADERSHIP TEAM</div>
        <div className={styles.items}>
          {leaders.map((item) => (
            <LeadershipItem key={item.name} item={item} />
          ))}
        </div>
      </div>
    </>
  );
};
