import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { InvestorsModel } from '..';
import { LinkIcon } from '../../../ui/Icon';
import styles from './investorItem.module.scss';
export const InvestorItem = ({ item }: { item: InvestorsModel }) => {
  const initialDescTop = '150px';
  const finalDescTop = '37%';
  const [descTop, setDescTop] = useState<string>(initialDescTop);

  const setHoverAnimations = () => {
    if (!isMobile) {
      setDescTop(finalDescTop);
    }
  };
  const removeHoverAnimations = () => {
    if (!isMobile) {
      setDescTop(initialDescTop);
    }
  };
  return (
    <>
      <div
        className={styles.investorItem}
        onMouseOver={setHoverAnimations}
        onMouseLeave={removeHoverAnimations}
        onClick={() =>
          isMobile &&
          (descTop == initialDescTop
            ? setDescTop(finalDescTop)
            : setDescTop(initialDescTop))
        }
      >
        <div
          className={styles.logo}
          style={{ background: item.color ?? 'white' }}
        >
          <img src={item.img} width="117px" alt="" />
          <a
            href={item.link}
            className={`${styles.link} frostyGlass`}
            style={{ top: descTop }}
          >
            <LinkIcon />
          </a>
        </div>
        <div className={styles.txt}>{item.name}</div>
      </div>
    </>
  );
};
