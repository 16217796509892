import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  IconDiscord,
  IconDropdown,
  IconInstagram,
  IconMedium,
  IconTwitter,
} from '../../ui/Icon';
import styles from './Header.module.scss';
import {
  DISCORD_URL,
  INSTAGRAM_URL,
  MEDIUM_URL,
  TWITTER_URL,
} from '../../constant';

const logoColors: string[] = [
  '#E54040',
  '#FFF96D',
  '#FFFACA',
  '#BDBCF8',
  '#B0E7FF',
  '#9CFCC3',
];

type PageProps = {
  noBg?: boolean;
};

export type NFTProps = {
  id: number;
  img: string;
  mintAddress: string;
  title: string;
  isStaked?: boolean;
  associatedTokenAddress: string;
  earned: number;
  rank: number;
  uri: string;
};

export const Header = ({ noBg }: PageProps) => {
  const [logoColorNum, setLogoColorNum] = useState<number>(1);
  const [showMintedBox, setShowMintedBox] = useState<boolean>(false);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<number>(0);

  useEffect(() => {
    console.log(showMenu);
    if (showMenu > 0) {
      document.body.className += ' ' + 'lock-scroll';
    } else {
      document.body.className = '';
    }
  }, [showMenu]);

  return (
    <div
      className={`${styles.headerBox} ${showMenu > 0 ? styles.bgBlack : ''}`}
    >
      <div className={styles.headerBtns}>
        <Link
          to="/"
          className={styles.logo}
          onMouseEnter={() => {
            setLogoColorNum((logoColorNum + 1) % logoColors.length);
          }}
        >
          <img
            className={styles.spaceRunnersLogo}
            src="/img/SPLogoNew.png"
            height={43}
            width={176}
          />
        </Link>
        <div
          className={styles.headerDropdown}
          onClick={() => {
            setShowMenu(showMenu === 1 ? 0 : 1);
          }}
        >
          ABOUT US <IconDropdown />
          {showMenu === 1 && (
            <>
              <div
                className={styles.headerbackdrop}
                onClick={() => {
                  setShowMenu(0);
                }}
              />
              <div className={styles.headerDropContent}>
                <div>
                  <a href="/#team">TEAM</a>
                  <a href="/#advisors">INVESTORS & ADVISORS</a>
                  <a href="/#news">NEWS FROM US</a>
                </div>
              </div>
            </>
          )}
        </div>
        <div
          className={styles.headerDropdown}
          onClick={() => {
            setShowMenu(showMenu === 2 ? 0 : 2);
          }}
        >
          EXPERIENCES <IconDropdown />
          {showMenu === 2 && (
            <>
              <div
                className={styles.headerbackdrop}
                onClick={() => {
                  setShowMenu(0);
                }}
              />
              <div className={styles.headerDropContent}>
                <div className={styles.experiences}>
                  <a href="https://spaceverse.spacerunners.com">SPACEVERSE</a>
                  <a href="/#drops">NFT DROPS</a>
                  <Link to="/designspace">DESIGNSPACE</Link>
                  <Link to="/rpc">RPC Service</Link>
                  <Link to="/old-closet">OLD CLOSET</Link>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className={styles.headerBtns}>
        <div className={styles.socialLink}>
          <a href={MEDIUM_URL} target="_blank">
            <IconMedium />
          </a>
          <a href={INSTAGRAM_URL} target="_blank">
            <IconInstagram />
          </a>
          <a href={TWITTER_URL} target="_blank">
            <IconTwitter />
          </a>
          <a href={DISCORD_URL} target="_blank">
            <IconDiscord />
          </a>
        </div>
        <div
          className={styles.hamburger}
          onClick={() => {
            setShowDropdown(!showDropdown);
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        {showDropdown && (
          <div
            className={styles.dropdown}
            onClick={() => {
              setShowDropdown(!showDropdown);
            }}
          >
            <div className={styles.menuItem}>
              <h3>ABOUT US</h3>

              <div>
                <a href="/#team">TEAM</a>
                <a href="/#advisors">INVESTORS & ADVISORS</a>
                <a href="/#news">NEWS FROM US</a>
              </div>
            </div>
            <div className={styles.menuItem}>
              <h3>EXPERIENCES</h3>

              <div>
                <Link to="/spaceverse">SPACEVERSE</Link>
                <a href="/#drops">NFT DROPS</a>
                <Link to="/designspace">DESIGNSPACE</Link>
                <Link to="/rpc">RPC Service</Link>
                <Link to="/old-closet">OLD CLOSET</Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
