import styles from './ComingSoon.module.scss';

export const ComingSoon = () => {
  return (
    <div className={styles.comingSoonPage} id="comingSoon">
      <div
        className={styles.comingSoonBox}
        onClick={() => {
          window.open('https://shop.ablo.ai', '_blank');
        }}
      >
        <div className={styles.comingSoonContent}>
          <h4>ABLO STORIES</h4>
          <p>
            Share your journey, wear your story.
            <br />
            <br />
            Ablo Stories empowers anyone to convert their cherished moments into
            custom-designed merchandise through AI-enhanced design tools. Create
            and order personalized items for yourself or as gifts in just a few
            seconds.
          </p>
          <div className={styles.submitRow}>
            <div className={styles.buttonbox}>
              <span
                style={{
                  lineHeight: '17px',
                  position: 'relative',
                  top: '1px',
                }}
              >
                TRY NOW
              </span>
            </div>
          </div>
        </div>

        <div className={styles.comingSoonVisual}>
          <video autoPlay muted>
            <source src="/video/stories.mp4" type="video/webm" />
          </video>
        </div>
      </div>
    </div>
  );
};
