import styles from './drops.module.scss';
import { DropItem } from './dropsItem';
export interface DropsModel {
  id: number;
  desc: string;
  title: string;
  footer: string;
  img: string;
  isSpecial?: boolean;
  link: string;
  chainImg: string;
}
const drops: DropsModel[] = [
  {
    id: 4,
    desc: 'Collect a unique set of digital toys of different rarities to claim your physical toy.',
    title: 'SMILEYWORLD artoys',
    footer: 'Join Now',
    img: 'img/drops/SmileyWorld.jpg',
    link: 'https://smiley.artoys.app/',
    chainImg: '',
  },
  {
    id: 5,
    desc: 'The Balmain x Space Runners limited edition Unicorn brings together the iconic physical Balmain Unicorn Sneakers paired with Digital Collectible NFT. Purchase now on OpenSea.',
    title: 'BALMAIN X SPACE RUNNERS',
    footer: 'Buy on Open Sea',
    img: 'img/drops/Balmain.png',
    link: 'https://opensea.io/collection/balmain-x-space-runners-unicorn',
    chainImg: 'img/drops/ethereum-eth-logo.png',
  },
  {
    id: 3,
    desc: 'An experiment to redefine fashion. What new possibilities can be unlocked when fashion meets the metaverse?',
    title: 'GEN 2: Nebula',
    footer: 'COMING SOON ON ETHEREUM',
    img: 'img/drops/gen2.png',
    isSpecial: true,
    link: '',
    chainImg: 'img/drops/ethereum-eth-logo.png',
  },
  {
    id: 0,
    desc: 'The first Fashion NFTs on Solana. Aimed to evoke nostalgia for the days we used to collect basketball shoes in the real world.',
    title: 'Gen1: NBA Champions collectıon',
    footer: 'Collaboration with Kyle Kuzma & Nick Young',
    img: 'img/drops/Nick.png',
    link: 'https://opensea.io/collection/space-runners',
    chainImg: 'img/drops/solana-sol-logo.png',
  },
  {
    id: 1,
    desc: `Join our cause to #EndBullying. All proceeds donated to Jake Paul's Boxing Bullies charity to prevent bullying in schools.`,
    title: 'Jake paul x Space Runners',
    footer: 'Collaboration with Jake Paul & Boxing Bullies',
    img: 'img/drops/jake.png',
    link: 'https://www.boxingbullies.com/',
    chainImg: 'img/drops/solana-sol-logo.png',
  },
  {
    id: 2,
    desc: 'What is fashion for a boxer? What you wear speaks as much as your jabs in the ring. This piece broke the record as highest valued auction on Solana.',
    title: 'Tyson Fury x Space Runners',
    footer: 'Collaboration with Tyson Fury',
    img: 'img/drops/Tyson.png',
    link: 'https://magiceden.io/auction/spacerunners_x_tyson_fury',
    chainImg: 'img/drops/solana-sol-logo.png',
  },
];
export const Drops = () => {
  return (
    <>
      <div className={`${styles.drops}`} id="drops">
        <div className={styles.dropsTitle}>ALL NFT DROPS</div>
        <div className={styles.dropItems}>
          {drops.map((drop: DropsModel) => (
            <DropItem key={drop.id} item={drop} />
          ))}
        </div>
      </div>
    </>
  );
};
