import { AdvisorItem } from './advisorItem';
import styles from './investorAdvisors.module.scss';
import { InvestorItem } from './investorItem';
export interface InvestorsModel {
  id: number;
  color: string;
  img: string;
  name: string;
  link: string;
}
export interface AdvisorsModel {
  img: string;
  id: number;
  name: string;
  lastName: string;
  company: string;
  role: string;
  link: string;
  linkedin: boolean;
}
const investors: InvestorsModel[] = [
  {
    id: 0,
    color: '#15182B',
    name: 'Pantera Capital',
    link: 'https://panteracapital.com/',
    img: 'img/investors/Pantera.svg',
  },
  {
    id: 1,
    color: 'black',
    name: 'Polychain Capital',
    link: 'https://polychain.capital/',
    img: 'img/investors/Polychain.png',
  },
  {
    id: 2,
    color: 'white',
    name: 'Accel',
    link: 'https://www.accel.com/',
    img: 'img/investors/Accel.svg',
  },
  {
    id: 3,
    color: 'white',
    name: 'Jump Capital',
    link: 'https://jumpcap.com/',
    img: 'img/investors/jump.svg',
  },
];
const advisors: AdvisorsModel[] = [
  {
    id: 0,
    name: 'Kevin',
    lastName: 'Ma',
    link: 'https://www.linkedin.com/in/kevinmahypebeast/?originalSubdomain=hk',
    role: 'Founder & CEO',
    company: 'Hypebeast',
    img: 'img/advisors/kevin ma.png',
    linkedin: true,
  },
  {
    id: 1,
    name: 'Yat',
    lastName: 'Siu',
    link: 'https://www.linkedin.com/in/yatsiu/?originalSubdomain=hk',
    role: 'Cofounder & CHAIRMAN',
    company: 'Animoca Brands',
    img: 'img/advisors/yat.png',
    linkedin: true,
  },
  {
    id: 2,
    name: 'Justin',
    lastName: 'Kan',
    link: 'https://www.linkedin.com/in/justinkan/',
    role: 'Co-founder',
    company: 'Twitch & Fractal',
    img: 'img/advisors/justin.png',
    linkedin: true,
  },
  {
    id: 3,
    name: 'Dan',
    lastName: 'Bunting',
    link: 'https://www.linkedin.com/in/danbunting/',
    role: 'Co-Studio Head, Treyarch',
    company: 'Call of Duty',
    img: 'img/advisors/dan.png',
    linkedin: true,
  },
  {
    id: 5,
    name: 'Ray',
    lastName: 'Chan',
    link: 'https://www.linkedin.com/in/raychan/?originalSubdomain=hk',
    role: 'Co-founder & CEO',
    company: '9GAG',
    img: 'img/advisors/ray.png',
    linkedin: true,
  },
  {
    id: 7,
    name: 'Steve',
    lastName: 'Goldfeder',
    link: 'https://www.linkedin.com/in/sgoldfed/',
    role: 'Co-founder & CEO',
    company: 'Arbitrum | Offchain Labs',
    img: 'img/advisors/steven.png',
    linkedin: true,
  },

  {
    id: 8,
    name: 'Kun',
    lastName: 'Gao',
    link: 'https://www.linkedin.com/in/kungao/',
    role: 'Co-founder & CEO',
    company: 'Crunchyroll',
    img: 'img/advisors/kun.png',
    linkedin: true,
  },
  {
    id: 9,
    name: 'Holly',
    lastName: 'Liu',
    link: 'https://www.linkedin.com/in/hollyhliu/',
    role: 'Co-founder',
    company: 'Kabam',
    img: 'img/advisors/holly.png',
    linkedin: true,
  },
  {
    id: 10,
    name: 'Isaac',
    lastName: 'Zu',
    link: 'https://mobile.twitter.com/thelibrarian_0x',
    role: 'COO',
    company: 'Nansen',
    img: 'img/advisors/isaac.png',
    linkedin: false,
  },
  {
    id: 11,
    name: 'Kevin',
    lastName: 'Lin',
    link: 'https://www.linkedin.com/in/kevinlin3/',
    role: 'Co-founder',
    company: 'Twitch',
    img: 'img/advisors/kevin lin.png',
    linkedin: true,
  },
  {
    id: 12,
    name: 'Patrick',
    lastName: 'Lee',
    link: 'https://www.linkedin.com/in/rottendoubt/',
    role: 'Co-founder & CEO',
    company: 'Rotten Tomatoes',
    img: 'img/advisors/patrick.png',
    linkedin: true,
  },
  {
    id: 13,
    name: 'Ashwin',
    lastName: 'Navin',
    link: 'https://www.linkedin.com/in/ashwinnavin/',
    role: 'Co-founder',
    company: 'BitTorrent',
    img: 'img/advisors/Ashwin.png',
    linkedin: true,
  },
  {
    id: 14,
    name: 'Randy',
    lastName: 'Chung',
    link: 'https://www.linkedin.com/in/randy-chung-1157b21/',
    role: 'CTO',
    company: 'WENEW',
    img: 'img/advisors/Randy.png',
    linkedin: true,
  },
  {
    id: 16,
    name: 'Mehmet',
    lastName: 'Korutürk',
    link: 'https://www.instagram.com/koruturk/',
    role: 'Co-founder',
    company: 'Vanguart Watches',
    img: 'img/advisors/Mehmet.png',
    linkedin: true,
  },
  {
    id: 4,
    name: 'Megan',
    lastName: 'Kaspar',
    link: 'https://www.instagram.com/megan.kaspar/?hl=en',
    role: 'Founding Member',
    company: 'RED DAO',
    img: 'img/advisors/megan.png',
    linkedin: false,
  },
];
export const InvestorAdvisors = () => {
  return (
    <>
      <div className={`${styles.investorAdvisors} container`} id="advisors">
        <div className={styles.title}>Investors &#38; Advisors</div>
        <div className={styles.investors}>
          {investors.map((item) => (
            <InvestorItem item={item} key={item.id} />
          ))}
        </div>
        <div className={styles.advisors}>
          {advisors.map((item) => (
            <AdvisorItem key={item.id} item={item} />
          ))}
        </div>
      </div>
    </>
  );
};
