import { OurNewsModel } from '..';
import styles from './ourNewsItem.module.scss';

export const OurNewsItem = ({ item }: { item: OurNewsModel }) => {
  return (
    <>
      <a href={item.link} target="_blank" className={styles.ourNewsItem}>
        <div className={styles.txt}>
          <div className={`${styles.title} textBody`}>{item.title}</div>
          <div className={`${styles.desc} textBody`}>{item.desc}</div>
        </div>
        <img src={item.img} />
      </a>
    </>
  );
};
