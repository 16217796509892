export const endDate = '2021-12-13 20:00';
export const CHAIN_NET = 'mainnet-beta';
//export const CHAIN_NET = 'devnet';

export const backendAPI = 'https://api-vote.spacerunners.com';
export const OLDCLOSET_ENDPOINT =
  'https://api-closet.spacerunners.com/api/burned-tokens?limit=10000';
export const SITE_KEY = '6LfXrG8eAAAAAKTskukWoh4SP_lXIhbHQR5_qkyt';
export const VTO_API_KEY = 'V44UypfOkJa9Hkxll6UcOYd2GaGpmoHucFt9TJ9fUA';

export const INSTAGRAM_URL = 'https://www.instagram.com/spacerunners/';
export const DISCORD_URL = 'https://discord.gg/2F2JXfYEae';
export const TWITTER_URL = 'https://twitter.com/spacerunnersnft';
export const MEDIUM_URL = 'https://medium.com/@spacerunners';

export const CASH_CONFIG = {
  candyMachineAddress: 'EAJ6w757boibxSWX9rDy4sbWsqc1tqDc6gnbe8QAGhDd',
  uuid: 'Dbps9P',
  config: 'Dbps9P51ntzWwu1pVWgC9V5PWL2aaW5ZUoBURo5sTpk3',
};

export type TypeDeadLine = {
  hasButton: boolean;
  endDate: string;
  title: string;
  btnText: string;
  nextup: string | null;
  target: boolean;
  url: string;
  price: number;
};

export const deadLines: TypeDeadLine[] = [
  {
    hasButton: false,
    endDate: '2021-12-23 04:00:00',
    title: 'Private Sale Round 1',
    nextup: null,
    btnText: 'JOIN DISCORD',
    target: true,
    url: DISCORD_URL,
    price: 3,
  },
  {
    hasButton: true,
    endDate: '2021-12-23 08:00:00',
    title: 'Private Sale Round 1',
    nextup: 'NEXT UP: PRIVATE SALE ROUND 2',
    btnText: 'CONNECT WALLET TO MINT',
    target: false,
    url: '/mint',
    price: 3,
  },
  {
    hasButton: false,
    endDate: '2021-12-23 08:00:00',
    title: 'Private Sale Round 1',
    nextup: null,
    btnText: 'JOIN DISCORD',
    target: true,
    url: DISCORD_URL,
    price: 3,
  },
  {
    hasButton: true,
    endDate: '2021-12-23 16:00:00',
    title: 'Private Sale Round 2',
    nextup: 'NEXT UP: PUBLIC SALE',
    btnText: 'CONNECT WALLET TO MINT',
    target: false,
    url: '/mint',
    price: 4,
  },
  {
    hasButton: false,
    endDate: '2021-12-23 19:00:00',
    title: 'Public Sale',
    nextup: null,
    btnText: 'CONNECT WALLET TO MINT',
    target: true,
    url: '/',
    price: 5,
  },
  {
    hasButton: false,
    endDate: '9999-12-31 00:00:00',
    title: 'Public Sale',
    nextup: null,
    btnText: 'CONNECT WALLET TO MINT',
    target: true,
    url: '/mint',
    price: 5,
  },
];
