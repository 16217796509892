import React, { useState } from 'react';
import { Term } from '../components/terms';

export const Terms: React.FC = () => {
  return (
    <>
      <Term />
    </>
  );
};
