import {
  DISCORD_URL,
  INSTAGRAM_URL,
  MEDIUM_URL,
  TWITTER_URL,
} from '../../constant';
import {
  IconBigDiscord,
  IconDiscord,
  IconEmail,
  IconInstagram,
  IconMedium,
  IconRunner,
  IconTwitter,
} from '../../ui/Icon';
import styles from './Community.module.scss';

export const Community = () => {
  return (
    <div className={styles.communityPage}>
      <div className={styles.communityBox}>
        <div className={styles.textContent}>
          <h4>JOIN THE MOVEMENT</h4>
          <h1>JOIN OUR COMMUNITY</h1>
          <div className={styles.buttonbox}>
            <div className={styles.socialLink}>
              <a className={styles.disabled} target="_blank">
                <IconDiscord />
              </a>
              <a href={INSTAGRAM_URL} target="_blank">
                <IconInstagram />
              </a>
              <a href={TWITTER_URL} target="_blank">
                <IconTwitter />
              </a>
            </div>
          </div>
        </div>
        <div className={styles.imgBox}>
          <img
            className={styles.img1}
            width="463px"
            src="/img/community/grid.png"
          />
          <img
            className={styles.sneakerImg}
            src="/img/community/communitysneaker.png"
          />
        </div>

        {/* <div className={styles.btns}>
        <a href={TWITTER_URL} target="_blank">
          <img src="/img/community/bigTwitter.svg" alt="" />
          FOLLOW TWITTER
        </a>
        <a href={DISCORD_URL} target="_blank">
          <IconBigDiscord />
          JOIN DISCORD
        </a>
        <a href={INSTAGRAM_URL} target="_blank">
          <img src="/img/community/bigInstagram.svg" alt="" />
          FOLLOW INSTAGRAM
        </a>
      </div> */}
      </div>
    </div>
  );
};
