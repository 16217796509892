import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { LeaderShipModel } from '..';
import { IconWhiteLinkedin } from '../../../ui/Icon';
import styles from './leadershipItem.module.scss';
export const LeadershipItem = ({ item }: { item: LeaderShipModel }) => {
  const initialDescTop = '300px';
  const finalDescTop = '8px';
  const [descTop, setDescTop] = useState<string>(initialDescTop);

  const setHoverAnimations = () => {
    if (!isMobile) {
      setDescTop(finalDescTop);
    }
  };
  const removeHoverAnimations = () => {
    if (!isMobile) {
      setDescTop(initialDescTop);
    }
  };
  return (
    <>
      <div
        className={styles.leadershipItem}
        onMouseOver={setHoverAnimations}
        onMouseLeave={removeHoverAnimations}
        onClick={() =>
          isMobile &&
          (descTop == initialDescTop
            ? setDescTop(finalDescTop)
            : setDescTop(initialDescTop))
        }
      >
        <div className={styles.imgWrapper}>
          <img src={item.img} alt="" />
          <a
            href={item.link}
            className={`${styles.desc}`}
            style={{ top: descTop }}
          >
            <div className={styles.media}>
              <IconWhiteLinkedin />
            </div>
          </a>
        </div>
        <div className={styles.name}>{item.name}</div>
        <div className={styles.role}>{item.role}</div>
      </div>
    </>
  );
};
