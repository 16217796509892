import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { OLDCLOSET_ENDPOINT } from '../../constant';
import { Header } from '../header';
import { BurnGroupItem } from './BurnGroupItem';
import { BurnItem } from './BurnItem';
import styles from './OldCloset.module.scss';

export type BurnData = {
  id: number;
  pub_key: string;
  burn_price: number;
};

export type BurnGroupData = {
  count: number;
  total_spent: number;
  avg_cost: 18.23;
  nfts: BurnData[];
};

export const OldCloset: React.FC = () => {
  const [burns, setBurns] = useState<{ [type: string]: BurnGroupData }>({});

  useEffect(() => {
    (async function () {
      try {
        const res = await fetch(OLDCLOSET_ENDPOINT);
        if (res.status === 200 || res.ok) {
          setBurns(await res.json());
        }
        console.log(res);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <>
      <div className={styles.header}>
        <Header />
      </div>
      <div className={styles.content}>
        <h1>OLD CLOSET</h1>
        <div className={styles.groupList}>
          {Object.keys(burns).map((burnDate: string, index: number) => (
            <div key={`burn-group-${index}`}>
              <BurnGroupItem burns={burns[burnDate]} burnDate={burnDate} />
            </div>
          ))}
        </div>
        <div className={styles.burnList}>
          {Object.keys(burns).map((burnDate: string, index: number) => (
            <div
              className={styles.burnContainer}
              key={`burn-container-${index}`}
              id={burnDate}
            >
              <div className={styles.burnTitle}>
                {burnDate}
                <span>{burns[burnDate].nfts.length} Victims</span>
              </div>
              <a href="#">Back to top</a>
              <div className={styles.burnBoxList}>
                {burns[burnDate].nfts.map((item: BurnData) => (
                  <div key={`burn-item-${item.id}`}>
                    <BurnItem burn={item} />
                  </div>
                ))}
              </div>
              <div className={styles.breakLine} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
